import { _ } from "vue-underscore";
import Multiselect from "vue-multiselect";
import { Function } from "core-js";
export default {
  name: "lead-email",
  data() {
    return {
      currentTab: "",
      staticTemplatesList: [],
      staticEmailHistoryList: [],
      staticTemplates: "",
      activeTab: "",
      showEmailBlock: false,
      selectedTemplate: null,
      selectedId: 0,
      body: "",
      subject: "",
      showEmailHistory: true,
      showRow: false,
      batchSize: 15,
      totalEmails: 0,
      emailsToShow: 15,
      showLoader: false,
      // backUrl: "email-compose",
      selectedEmail: [],
      emailData: {
        subject: "",
        body: "",
      },
      isSelfChecked: false,
      emails: [],
      userEmail1: "",
      userEmail: [],
      clearEmails: false,
      buttonDisabled: false,
      paymentFormOpenTime: false,
      editedContent: {},
      editorConfig: {
        btns: [],
      },
    };
  },
  components: {
    Multiselect,
  },
  props: {
    selectedLead: Object,
  },
  computed: {
    visibleEmails() {
      return this.staticEmailHistoryList.slice(0, this.emailsToShow);
    },
    fullName: function () {
      return this.user.firstname + " " + this.user.lastname;
    },
    renderEmailBody() {
      return this.emailData.body.replace(
        /class="([^"]*\beditable\b[^"]*)"/g,
        (match, classAttributes) => {
          if (classAttributes.includes('style="')) {
            const updatedStyle = classAttributes.replace(
              /(style="[^"]*)"/,
              `$1 outline: none; box-shadow: none;`
            );
            return `class="${updatedStyle}" contenteditable="true" style="outline: none; box-shadow: none;"`;
          } else {
            return `${match} contenteditable="true" style="outline: none; box-shadow: none;"`;
          }
        }
      );
    },
    // emailsList() {
    //   if (
    //     this.selectedLead &&
    //     this.selectedLead.emails &&
    //     this.selectedLead.emails.length > 0
    //   ) {
    //     return this.selectedLead.emails;
    //   }
    //   return [];
    // },
    emailsList() {
      if (
        this.selectedLead &&
        this.selectedLead.client.client_emails &&
        this.selectedLead.client.client_emails.length > 0
      ) {
        return this.selectedLead.client.client_emails;
      }
      return [];
    },
    maskedEmailsList() {
      return this.emailsList.map((email) => {
        const atIndex = email.value.indexOf("@");
        if (atIndex > 2) {
          const maskedValue =
            email.value.substring(0, 2) +
            "*".repeat(atIndex - 2) +
            email.value.substring(atIndex - 1);
          return { ...email, maskedValue };
        } else {
          // Handle cases where the email is too short to mask properly
          return { ...email, maskedValue: email.value };
        }
      });
    },
    // maskedEmailsList() {
    //   return this.emailsList.map((email) => {
    //     const atIndex = email.value.indexOf("@");
    //     const maskedValue =
    //       email.value.substring(0, 2) +
    //       "*".repeat(atIndex - 2) +
    //       email.value.substring(atIndex - 1);
    //     return { ...email, maskedValue };
    //   });
    // },
    user() {
      return this.$store.state.userDetail;
    },
  },
  watch: {
    selectedLead: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue.id) {
          this.getStaticTemplate();
          this.getEmailHistory();
        }
      },
    },
    selectedTemplate(newValue) {
      this.emailData = newValue;
    },
    clearEmails(val) {
      if (val) {
        this.clearSelectedEmails();
      }
    },
    isSelfChecked(newVal, oldVal) {
      if (newVal === true) {
        const atIndex = this.user.email.indexOf("@");
        const maskedValue =
          this.user.email.substring(0, 2) +
          "*".repeat(atIndex - 2) +
          this.user.email.substring(atIndex - 1);
        this.selectedEmail.push({
          value: this.user.email,
          maskedValue: maskedValue,
        });
      } else {
        const index = this.selectedEmail.findIndex(
          (email) => email.value === this.user.email
        );
        if (index !== -1) {
          this.selectedEmail.splice(index, 1);
        }
      }
    },
    selectedEmail(newVal, oldVal) {
      const userEmailIndex = newVal.findIndex(
        (email) => email.value === this.user.email
      );
      if (userEmailIndex === -1) {
        this.isSelfChecked = false;
      }
    },
  },
  methods: {
    parseOpenTimes(openTimes) {
      if (openTimes) {
        return openTimes.split(",");
      }
      return [];
    },
    openPaymentFormTime(key) {
      let _vm = this;
      _vm.paymentFormOpenTime = _vm.paymentFormOpenTime === key ? null : key;
    },
    formatDateTime(timestamp) {
      const date = new Date(timestamp);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const month = months[date.getMonth()];
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear().toString().slice(2);
      const time = timestamp.slice(11, 16);

      return `${month}/${day}/${year} ${time}`;
    },
    // maskEmail(email) {
    //   const atIndex = email.indexOf("@");
    //   if (atIndex > 1) {
    //     const masked =
    //       email.substring(0, 2) +
    //       "*".repeat(atIndex - 3) +
    //       email.substring(atIndex - 1);
    //     return masked;
    //   }
    // },

    maskEmail(email) {
      const atIndex = email.indexOf("@");
      if (atIndex > 2) {
        const masked =
          email.substring(0, 2) +
          "*".repeat(atIndex - 3) +
          email.charAt(atIndex - 1) +
          email.substring(atIndex);
        return masked;
      } else if (atIndex === 2) {
        return email.substring(0, 2) + "*" + email.substring(atIndex);
      } else if (atIndex === 1) {
        return email.charAt(0) + "*" + email.substring(atIndex);
      } else if (atIndex === 0) {
        return "*" + email.substring(atIndex);
      }
      return email;
    },
    isLinkDisabled(staticData) {
      return staticData.use_count !== 0 && staticData.use_type === "Single Use";
    },
    clearSelectedEmails() {
      this.selectedEmail = [];
    },
    sendEmail() {
      const _vm = this;
      const selectedEmail1 = [];
      _vm.selectedEmail.forEach((element) => {
        selectedEmail1.push(element.value);
      });

      _vm.buttonDisabled = true;

      _vm.emailData["toEmails"] = selectedEmail1;
      _vm.emailData.lead_id = _vm.selectedLead.id;

      let editedHtml = _vm.renderEmailBody;
      let editedContentExist = false;

      for (const tagName in _vm.editedContent) {
        const editedContent = _vm.editedContent[tagName];
        const tagRegex = new RegExp(
          `<${tagName}[^>]*class="editable"[^>]*>[\\s\\S]*?<\\/${tagName}>`,
          "g"
        );

        if (editedContent.trim() !== "") {
          editedContentExist = true;
          const replacement = `<${tagName} class="editable" contenteditable="true">${editedContent}</${tagName}>`;
          editedHtml = editedHtml.replace(tagRegex, replacement);
        }
      }

      if (editedContentExist) {
        _vm.emailData.body = editedHtml;
      }

      _vm.axios
        .post("/static-templates-email", _vm.emailData)
        .then((response) => {
          _vm.getEmailHistory();
          _vm.showEmailHistory = true;
          _vm.buttonDisabled = false;
          this.isSelfChecked = false;
          // _vm.staticTemplatesList = '';
          _vm.getStaticTemplate();
          _vm.selectedEmail = [];
        })
        .catch((error) => {});
    },

    customLabel({ value }) {
      return `${value}`;
    },
    toggleEmailInput() {
      this.isSelfChecked = !this.isSelfChecked;
    },
    disableButton() {
      return !this.selectedEmail.length;
    },
    handleInput(event) {
      const editableElement = event.target.closest(".editable");
      if (editableElement) {
        const tagName = editableElement.tagName.toLowerCase();
        this.editedContent[tagName] = editableElement.innerHTML;
      }
    },
    clearForm() {
      this.selectedEmail = [];
      this.emailData.subject = "";
      this.emailData.body = "";
      this.isSelfChecked = false;
    },
    handleScroll() {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.clientHeight;

      if (scrollY + windowHeight >= bodyHeight - 200) {
        this.loadNextBatch();
      }
    },
    loadNextBatch() {
      this.emailsToShow += this.batchSize;
    },
    updateShowEmailHistory(newValue) {
      this.showEmailHistory = newValue;
    },
    // toggleRowVisible(index) {
    //   if (this.showRow === index) {
    //     this.showRow = null;
    //   } else {
    //     this.showRow = index;
    //   }
    // },

    toggleRowVisible(staticEmail) {
      let emailData = this.staticEmailHistoryList.find(
        (email) => email.id === staticEmail.id
      );

      if (!emailData || !emailData.email_body) {
        this.axios
          .get(`/get-email-body/${staticEmail.id}`)
          .then((response) => {
            let data = JSON.parse(atob(response.data.data));

            if (emailData) {
              emailData.email_body = data.email_body;
            } else {
              this.staticEmailHistoryList.push({
                id: staticEmail.id,
                email_body: data.email_body,
              });
            }

            staticEmail.email_body = data.email_body;
            this.showRow =
              this.showRow === staticEmail.id ? null : staticEmail.id;
          })
          .catch((error) => {});
      } else {
        staticEmail.email_body = emailData.email_body;
        this.showRow = this.showRow === staticEmail.id ? null : staticEmail.id;
      }
    },
    changeTab(data) {
      //     if (data != 'email-compose'){
      this.showEmailHistory = false;
      this.currentTab = data.name;
      this.selectedTemplate = data;
      // if (!this.originalBody) {
      //   // Store the original template body content if it's not already stored
      //   this.originalBody = this.emailData.body;
      // }
      // this.emailData.body = this.originalBody;
      //     }else{
      //         this.currentTab = data;
      //         this.selectedTemplate = {};
      //     }
    },

    getStaticTemplate() {
      const _vm = this;

      const leadId = _vm.selectedLead.id;
      this.axios
        .get("/static-templates-groups/" + leadId)
        .then(function (response) {
          _vm.staticTemplatesList = response.data.data;
        })
        .catch(function () {});
    },
    getEmailHistory() {
      const _vm = this;
      _vm.showLoader = true;
      const leadId = _vm.selectedLead.id;
      const apiEndpoint = "/static-email-history/" + leadId;
      this.axios
        .get(apiEndpoint, _vm.staticEmailHistoryList)
        .then(function (response) {
          let encryptedData = response.data.data;
          let decryptedData = atob(encryptedData);
          let data = JSON.parse(decryptedData);
          _vm.staticEmailHistoryList = data;
          _vm.showLoader = false;
          _vm.showRow = false;
        })
        .catch(function () {});
    },
    removeEditableAttributes(html) {
      return html.replace(/contenteditable="true"/g, "");
    },
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    this.originalBody = "";
    this.clearForm();
  },
  mounted() {
    $("#dropdownselect").on(
      "multiselect:opening multiselect:closing",
      function (event) {
        var $searchfield = $(this).parent().find(".multiselect-search__field");
        $searchfield.prop("disabled", true);
      }
    );
    const agreeCheckbox = document.getElementById("customCheck-30");
    const sendButton = document.getElementById("send-btn");

    agreeCheckbox.addEventListener("change", function () {
      if (this.checked) {
        sendButton.removeAttribute("disabled");
      } else {
        sendButton.setAttribute("disabled", true);
      }
    });
    // window.addEventListener("scroll", this.handleScroll);
  },
};
