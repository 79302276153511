import store from "@/store/index.js";

export default {
  data() {
    return {
      selectedId: 0,
      text: "",
      to: "",
      client_id: "",
      showLoader: false,
      smsList: [],
      smsTemplateList: [],
      isSendDisabled: true,
      file: "", // Store the selected file here
      filePreview: "", // URL for image preview
      showPreviewModal: false,
      communicationBucketURL: process.env.VUE_APP_COMMUNICATION_BUCKET_URL,
    };
  },
  props: {
    selectedLead: Object,
  },
  watch: {
    selectedLead: function (newVal) {
      if (newVal && newVal.client_id) {
        this.getSms();
        this.getSmsStaticTemplates();
      }
    },
  },
  computed: {
    contactList() {
      if (
        this.selectedLead &&
        this.selectedLead.client &&
        this.selectedLead.client.client_phones
      ) {
        const phoneList = this.selectedLead.client.client_phones;
        const preferredContact = phoneList.find(
          (contact) => contact.preferred === 1
        );
        this.to = preferredContact
          ? preferredContact.prefix + preferredContact.value
          : "";
        return phoneList;
      }
      return [];
    },
  },
  methods: {
    triggerFileUpload() {
      // Trigger the hidden file input when paperclip icon is clicked
      this.$refs.fileInput.click();
    },
    openImage(url) {
      window.open(url, "_blank");
    },
    showImagePreview() {
      // This method shows the image preview modal
      this.showPreviewModal = true;
    },
    handleFileUpload(event) {
      // Store the selected file and show preview
      const file = event.target.files[0];
      if (file) {
        this.file = file;
        this.showPreviewModal = true;

        // If the file is an image, generate a preview URL
        if (file.type.startsWith("image/")) {
          this.filePreview = URL.createObjectURL(file);
        }

        this.checkSendDisabled();
      }
    },
    keepFile() {
      this.showPreviewModal = false;
      this.isSendDisabled = false;
    },
    discardFile() {
      let _msg = "Are you sure you want to Discard Image?";
      this.$dialog
        .confirm(_msg)
        .then(() => {
          this.file = "";
          this.filePreview = "";
          this.showPreviewModal = false;
          this.checkSendDisabled();
        })
        .catch(function () { });
    },
    getSmsStaticTemplates() {
      const lead_id = this.selectedLead.id;
      this.axios
        .get(`/sms-static-templates-list/` + lead_id)
        .then((response) => {
          this.smsTemplateList = response.data.data;
        })
        .catch((error) => { });
    },
    openBody(index) {
      const selectedTemplate = this.smsTemplateList[index];
      this.text = selectedTemplate.body;
      this.checkSendDisabled();
    },
    maskContactValue(value) {
      if (value.length < 6) return value;
      const visibleChars = 2;
      const hiddenChars = value.length - visibleChars - 3;
      const maskedValue =
        value.substring(0, visibleChars) +
        "*".repeat(hiddenChars) +
        value.substring(value.length - 3);
      return maskedValue;
    },
    maskChatContactValue(value) {
      if (value.length < 6) return value;
      const visibleChars = 4;
      const hiddenChars = value.length - visibleChars - 3;
      const maskedValue =
        value.substring(0, visibleChars) +
        "*".repeat(hiddenChars) +
        value.substring(value.length - 3);
      return maskedValue;
    },
    add() {
      this.isSendDisabled = true;
      store.state.isLoaderShow = false;
      if ((!this.text || this.text.trim().length === 0) && !this.file) {
        return;
      }
      this.axios
        .post("/sms-send", {
          to: this.to,
          text: this.text,
          media: this.file.name,
        })
        .then((response) => {
          store.state.isLoaderShow = false;
          this.text = "";
          this.file = "";
          this.getSms();
          this.checkSendDisabled();
        })
        .catch(function () {
          this.text = "";
          this.file = "";
        });
    },
    getSms() {
      const client_id = this.selectedLead.client.id;
      const lead_id = this.selectedLead.id;
      this.axios
        .get(`/lead-sms-chat?clientId=${client_id}&leadId=${lead_id}`)
        .then((response) => {
          let encryptedData = response.data.data;
          let decryptedData = atob(encryptedData);
          let data = JSON.parse(decryptedData);
          this.smsList = data;
          this.scrollToBottom();
        })
        .catch((error) => { });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    checkSendDisabled() {
      if (!this.text || this.text.trim().length === 0) {
        this.isSendDisabled = true;
      } else {
        this.isSendDisabled = false;
      }
    },
  },
  mounted() {
    let _vm = this;
    _vm.getSmsStaticTemplates();
    _vm.scrollToBottom();
    _vm.timer = setInterval(_vm.getSms, 5000);
    // this.getSms();
    if (
      this.selectedLead &&
      this.selectedLead.client &&
      this.selectedLead.client.id
    ) {
      this.getSms();
    }
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
