import quotationModal from "./quote-modal/quotation-modal.vue";
import headerList from "@/components/common/header-list.vue";
import previewMail from "./preview-mail.vue";
import createSale from "./create-sale.vue";
import multiSale from "./create-Multisale.vue";
import leadpax from "./lead-PAX.vue";
import { _ } from "vue-underscore";
import paginationMixin from "@/Mixins/paginationMixin";

export default {
  data: () => ({
    sales: {
      pnr: "",
      lead_id: "",
      quotation_id: "",
      sale_notification_to: [],
    },
    quotationList: [
      {
        paymentFormOpenTime: false,
      },
    ],
    quotation: [],
    id: "",
    filterBy: "Recently Added ",
    error: "",
    title: "",
    showLoader: false,
    showPAX: false,
    showModal: false,
    showMail: false,
    // showmultiSale: false,
    quotationIds: [],
    showTable: false,
    selectedQuotation: {},
    quotation_ids: [],
    expandedRow: null,
    airlineslist: [],
    loading: false,
    showsellingprice: false,
    selectedQuotation: null,
    adult_selling_price: 0,
    child_selling_price: 0,
    infant_selling_price: 0,
    adultError: "",
    childError: "",
    infantError: "",
    // TotalQuotation:'',
    selectIds: [],
    isMinimized: false,
    userId: JSON.parse(atob(localStorage.getItem('user'))).id
  }),
  props: {
    selectedLead: Object,
    showCreateSale: Boolean,
    showmultiSale: Boolean,
    // leadId: {},
    quotationId: [],
    selectedPassengers: [],
    selectedEmail: {},
  },
  watch: {
    showMail(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        this.uncheckAll();
        this.selectIds = [];
      }
    },
  },
  mixins: [paginationMixin],
  components: {
    "quotation-modal": quotationModal,
    "preview-mail": previewMail,
    "create-sale": createSale,
    "lead-Pax": leadpax,
    "Multi-sale": multiSale,
  },
  computed: {
    isSelfLead() {
      return this.selectedLead?.assign_to == this.userId;
    },
    isExpertSelfLead() {
      return this.selectedLead?.expert_assign_to == this.userId;
    },
    cursorStyle() {
      return this.quotation.status === "Sent" ? "not-allowed" : "pointer";
    },
    leadId() {
      return this.$store.state.leadId;
    },
    isCheckboxDisabled() {
      if (this.isSelfLead) {
        return this.quotationList.map((quotation) => {
          return (
            quotation.type === "Mixed" ||
            quotation.sale_status === "Sold" ||
            quotation.adult_selling_price === "0.00" ||
            quotation.child_selling_price === "0.00" ||
            quotation.infant_selling_price === "0.00"
          );
        });
      } else {
        return this.quotationList.map((quotation) => {
          return (
            !this.isCreateSalePermission ||
            quotation.type === "Mixed" ||
            quotation.sale_status === "Sold" ||
            quotation.adult_selling_price === "0.00" ||
            quotation.child_selling_price === "0.00" ||
            quotation.infant_selling_price === "0.00"
          );
        });
      }
    },
    isPQButtonDisable() {
      if (this.isSelfLead) {
        return this.quotationList.map((quotation) => {
          return (
            quotation.sale_status === "Sold" ||
            quotation.adult_selling_price === "0.00" ||
            quotation.child_selling_price === "0.00" ||
            quotation.infant_selling_price === "0.00"
          );
        });
      } else {
        return this.quotationList.map((quotation) => {
          return (
            !this.isSendPqPermission ||
            quotation.sale_status === "Sold" ||
            quotation.adult_selling_price === "0.00" ||
            quotation.child_selling_price === "0.00" ||
            quotation.infant_selling_price === "0.00"
          );
        });
      }
    },
    isChildShow() {
      if (
        this.selectedLead &&
        this.selectedLead.trips &&
        this.selectedLead.trips.length > 0
      ) {
        return this.selectedLead.trips[0].children > 0;
      }
      return false;
    },
    isAdultShow() {
      if (
        this.selectedLead &&
        this.selectedLead.trips &&
        this.selectedLead.trips.length > 0
      ) {
        return this.selectedLead.trips[0].adults > 0;
      }
      return false;
    },
    isInfantShow() {
      if (
        this.selectedLead &&
        this.selectedLead.trips &&
        this.selectedLead.trips.length > 0
      ) {
        return this.selectedLead.trips[0].infants > 0;
      }
      return false;
    },

    isPriceQuotePermission() {
      const permission = this.hasPriceQuotedPermission();
      return permission;
    },
    isSendPqPermission() {
      const permission = this.hasSendPQ1Permission();

      return permission;
    },
    shouldDisableSellingPricepopup() {
      return this.isSelfLead || this.isAddSellingPricePermission;
    },
    isAddSellingPricePermission() {
      const permission = this.hasAddSellingPricePermission();

      return permission;
    },
    isCreateSalePermission() {
      const permission = this.hasCreateSalePermission();
      return permission;
    },
    selectAllList() {
      if (this.quotationList.length > 0) {
        const enabledQuotations = this.quotationList.filter(
          (quotation) => quotation.status !== "Sold"
        );
        return (
          enabledQuotations.length > 0 &&
          enabledQuotations.every((quotation) => quotation.isSelected)
        );
      }
      return false;
    },

    selectedQuotationCount() {
      let ids = [];
      this.quotationList.forEach((element) => {
        if (element.isSelected) {
          ids.push(element.id);
        }
      });
      return ids.length;
    },
  },
  methods: {
    openPaymentFormTime(index) {
      if (this.quotationList[index]) {
        this.$set(
          this.quotationList[index],
          "paymentFormOpenTime",
          !this.quotationList[index].paymentFormOpenTime
        );
      }
    },
    formatDateTime(timestamp) {
      const date = new Date(timestamp);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const month = months[date.getMonth()];
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear().toString().slice(2);
      const time = timestamp.slice(11, 16);

      return `${month}/${day}/${year} ${time}`;
    },
    getFullGdsName(abbreviation) {
      const gdsMapping = {
        A: "Apollo",
        S: "Sabre",
        M: "Amadeus",
        G: "Galileo",
      };
      return gdsMapping[abbreviation] || abbreviation;
    },
    selectInputText(inputElement) {
      inputElement.select();
    },
    openPopup(quotation) {
      this.selectedQuotation = { ...quotation };
      this.showsellingprice = true;
    },
    saveSellingPrices() {
      this.adultError = false;
      this.childError = false;
      this.infantError = false;

      if (this.selectedQuotation) {
        const {
          adult_selling_price,
          child_selling_price,
          infant_selling_price,
        } = this.selectedQuotation;
        if (
          !parseInt(adult_selling_price) > 0 &&
          !parseInt(child_selling_price) > 0 &&
          !parseInt(infant_selling_price) > 0
        ) {
          this.adultError =
            !parseInt(adult_selling_price) > 0 || !adult_selling_price;
          this.childError =
            !parseInt(child_selling_price) > 0 || !child_selling_price;
          this.infantError =
            !parseInt(infant_selling_price) > 0 || !infant_selling_price;
          return;
        }

        let data = {
          id: this.selectedQuotation.id,
          adult_selling_price,
          child_selling_price,
          infant_selling_price,
        };

        this.axios
          .post("quotation-update-selling-price", data)
          .then((response) => {
            // Reset errors and hide modal on successful submission
            this.adultError = false;
            this.childError = false;
            this.infantError = false;
            this.getAll();
            this.showsellingprice = false;
          })
          .catch((error) => { });
      }
    },
    resetError(field) {
      switch (field) {
        case "adult":
          this.adultError = false;
          break;
        case "child":
          this.childError = false;
          break;
        case "infant":
          this.infantError = false;
          break;
        default:
          break;
      }
    },

    // saveSellingPrices() {
    //     let _vm = this;
    //     let data = {
    //         id: _vm.quotation.id,
    //         adult_selling_price: _vm.quotation.adult_selling_price,
    //         child_selling_price: _vm.quotation.child_selling_price,
    //         infant_selling_price: _vm.quotation.infant_selling_price
    //     };

    //     this.axios
    //         .post("quotation-update-selling-price", data)
    //         .then(function (response) {
    //           this.showsellingprice = false;
    //         });
    // },
    updatebookable(id) {
      const record = this.quotationList.find((record) => record.id === id);
      this.quotation_id = id;
      this.axios.post(`/quotation-bookable/${this.quotation_id}`);
    },
    hasPriceQuotedPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-add-pq-in-others-lead";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasSendPQ1Permission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-send-pq-for-others";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasAddSellingPricePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-add-selling-price-in-others-pq";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasCreateSalePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-create-sale-for-others";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    createSale(id) {
      this.quotation_id = id;
      this.showCreateSale = true;
    },

    MultipleSale(id) {
      this.quotation_id = id;
      this.showmultiSale = true;
    },
    addMultipleSale() {
      this.sales["lead_id"] = this.leadId;
      this.sales["quotation_ids"] = [];
      this.quotationList.forEach((element) => {
        if (element.isSelected) {
          this.sales["quotation_ids"].push(element.id);
        }
      });
      this.sales["client_passenger_ids"] = this.selectedPassengers;
      this.sales["selected_email"] = this.selectedEmail;
      let sale = {
        ...this.sales,
      };
      sale.sale_notification_to = this.sales.sale_notification_to.join();
      let _vm = this;
      this.axios
        .post("/sales", sale)
        .then(function (response) {
          _vm.getAll();
          window.open("lead/" + this.$route.params.id + "#lead-sales");
          _vm.$emit("sale-create");
        })
        .catch(function (error) { });
    },

    toggleExpanded(index) {
      this.expandedIndex = this.expandedIndex === index ? -1 : index;
    },
    emailClass(status) {
      switch (status) {
        case "New":
          return "btn-secondary";
        case "Sent":
          return "btn-warning";
        case "Sold":
          return "btn-danger";
        case "Ex-Sale":
          return "btn-dark";
        default:
          break;
      }
    },
    hideRecord(id) {
      const record = this.quotationList.find((record) => record.id === id);
      record.isActive = 0;
      this.axios.post(`/quotation-hide/${id}`, { isActive: 0 });
    },
    unhideRecord(id) {
      const record = this.quotationList.find((record) => record.id === id);
      record.isActive = 1;
      this.axios.post(`/quotation-hide/${id}`, { isActive: 1 });
    },
    // addQuotation() {
    //   this.selectedQuotation = {};
    //   this.title = "Add Quotation";
    //   this.showModal = true;
    // },
    addQuotation() {
      this.selectedQuotation = {};
      this.title = "Add Quotation";
      this.showModal = true;
      this.isMinimized = false;
    },

    clonePQ(quotation) {
      const newQuotation = _.clone(quotation);
      delete newQuotation["id"];
      delete newQuotation["milageprograms"];
      this.selectedQuotation = newQuotation;
      this.title = "Quotation";
      this.showModal = true;
    },

    openModal(quotation) {
      this.selectedQuotation = { ...quotation };
      this.title = "Quotation";
      this.showModal = true;
    },
    checkUncheckedList(event) {
      this.quotationList.forEach((element) => {
        if (
          !(
            this.selectedLead.status === "Unassigned" ||
            element.type === "Mixed" ||
            (!this.isSelfLead &&
              !this.isCreateSalePermission) ||
            !this.isSendPqPermission ||
            element.sale_status == "Sold" ||
            element.adult_selling_price === "0.00" ||
            element.child_selling_price === "0.00" ||
            element.infant_selling_price === "0.00"
          )
        ) {
          element.isSelected = event.target.checked && element.isActive === 1;
        }
      });
    },
    paxshow() {
      // this.quotation_id = id;
      this.showPAX = true;
    },
    idsAccordingSelectSerial(id) {
      // Iterate over quotationList
      this.quotationList.forEach((element) => {
        if (element.id === id) {
          // If isSelected is true, push the id into the selectIds array
          if (!element.isSelected) {
            if (!this.selectIds.includes(id)) {
              this.selectIds.push(id);
            }
          } else {
            // If isSelected is false, remove the id from the selectIds array
            const index = this.selectIds.indexOf(id);
            if (index !== -1) {
              this.selectIds.splice(index, 1);
            }
          }
        }
      });
    },
    mailQuotation(id) {
      let _vm = this;
      _vm.quotationIds = [];
      if (id) {
        _vm.quotationIds = [id];
      } else {
        _vm.quotationIds.push(..._vm.selectIds);
        // _vm.quotationList.forEach((element) => {
        //   if (element.isSelected) {
        //     _vm.quotationIds.push(element.id);
        //   }
        // });
      }
      if (_vm.quotationIds.length == 0) {
        return;
      } else {
        _vm.showMail = true;
      }
    },
    uncheckAll() {
      this.quotationList.forEach((quotation) => {
        quotation.isSelected = false;
      });
    },
    getQueryString() {
      let queryString = "?filterBy=" + this.filterBy;
      return queryString;
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      _vm.showModal = false;
      _vm.showMail = false;
      _vm.showCreateSale = false;
      this.axios
        .get("/quotation-by-lead/" + this.$route.params.id + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let encryptedData = response.data.data;
          let decryptedData = atob(encryptedData);
          try {
            let data = JSON.parse(decryptedData);
            data.forEach((element) => {
              element["isSelected"] = false;
            });
            _vm.quotationList = data;
          } catch (error) { }
        })
        .catch(function (error) { });
    },
    changeTab(tab) {
      this.currentTab = tab;
      window.location.hash = tab;
    },
    toggleRow(k) {
      if (this.expandedRow === k) {
        this.expandedRow = null;
      } else {
        this.expandedRow = k;
      }
    },
    // getTotalQuotation(){
    //   let totalQuotation = this.quotationList.length();
    //   return totalQuotation;
    // }
  },
  mounted() {
    this.getAll();
    if (window.location.hash && window.location.hash.length > 0) {
      let hash = window.location.hash.split("#")[1];
      this.changeTab(hash);
    } else {
      this.changeTab("lead-info");
    }
    this.filterBy = "Recently Added";

    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  },
};
