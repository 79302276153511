<template>
  <div id="leadFeedbackComments-modal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal modal-wrapper">
          <div class="modal-dialog" role="document" :class="{ 'modal-closing': isClosing }">
            <div class="modal-content" style="background-color: white;">
              <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel2">Feedback / Comments ({{
                  selectedLead.client_name
                }}-{{ selectedLead.id }})</h6>

                <button type="button" class="close" aria-label="Close" @click="closeModal">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <ValidationObserver ref="replyfeedback" v-slot="{ handleSubmit }">
                  <form novalidate @submit.prevent="handleSubmit(addComment)">
                    <div class="col-md-12 p-0">
                      <div class="form-group mb-3">
                        <input type="hidden" v-model="selectedLeadRating" name="rating" />
                        <div class="ratting">
                          <ul class="ratting-item d-flex p-0 m-0 ptr">
                            <li v-for="(rate_request, index) in 5" :key="index" class="full"
                              @click="setSelectedLeadRating(rate_request)">
                              <i class="fa-solid fa-star fa-xl mr-1" style="color: #ac00e6"
                                v-if="selectedLeadRating >= rate_request" />
                              <i class="fa-regular fa-star fa-xl mr-1" style="color: #ac00e6" v-else />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 p-0" v-show="selectedLeadSelfLeadAttribute || isCloseOtherLeadPermission">
                      <div class="form-group mb-3">
                        <select v-model="selectedReason.reason" @change="handleSelection"
                          :disabled="isSelectDisabled || selectedLead.is_approval_pending === 1 || selectedLead.is_closed == 1 || selectedLead.status == 'Sold'"
                          class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                          <option value="" disabled selected>
                            Closing Reason
                          </option>
                          <option value="Move to bonus queue">
                            Move to bonus queue
                          </option>
                          <option value="Duplicate lead">
                            Duplicate lead
                          </option>
                          <option value="Contact info is not correct">
                            Contact info is not correct
                          </option>
                          <option value="Not reachable -Followup done">
                            Not reachable -Followup done
                          </option>
                          <option value="Ask for Referral Client Approval">
                            Ask for Referral Client Approval
                          </option>
                          <option value="Ask for Diamond Return Client Approval">
                            Ask for Diamond Return Client Approval
                          </option>
                          <option value="Client is not ready yet">
                            Client is not ready yet
                          </option>
                          <option value="Travel date past">
                            Travel date past
                          </option>
                          <option value="Trip cancelled">Trip cancelled</option>
                          <option value="Booked Somewhere else">
                            Booked Somewhere else
                          </option>
                          <option value="Another agent working with client">
                            Another agent working with client
                          </option>
                          <option value="Other specific reason">
                            Other specific reason
                          </option>
                          <option v-for="(company, index) in companies" :key="index"
                            :value="'Transferred to ' + company.name">
                            Transferred to {{ company.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12 p-0">
                      <ValidationProvider vid="comment" name="Comment" rules="required" v-slot="{ errors }">
                        <div class="form-group mb-3">
                          <textarea id="newCommentDiv" type="text" @input="enableValidation" v-model="newComment"
                            style="border-radius: 8px" rows="4" placeholder="Enter Comment..."
                            class="au-input au-input--full form-control placeholderpadding"
                            :class="applyValidation && errors.length > 0 ? ' is-invalid' : ''"></textarea>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group mb-3 ml-2 float-right">
                          <button type="button" class="btn btn-primary" @click="addComment(0)">
                            Save Comment
                          </button>
                        </div>
                        <div class="form-group mb-3  float-right"
                          v-if="!selectedLeadSelfLeadAttribute && isleadsFeedBackPermission">
                          <button type="button" :disabled="selectedReason.reason !== '' || selectedLead.is_closed == 1"
                            class="btn btn-warning" @click="addComment(1)">
                            Ask Feedback
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
                <div class="row">
                  <div class="col-md-12">
                    <span>Comment / Feedback</span>
                    <div class="d-flex border p-1 commentDivHeight">
                      <!-- <span style="width: -webkit-fill-available;"> -->
                      <ul class="p-0" style="width: -webkit-fill-available;">
                        <li v-for="(comment, index) in lead_feedback_comment" :key="index">
                          <div class="m-0 mt-1">
                            <span v-if="comment.is_transferred === 1" class="text-danger fontbold mr-1">
                              {{ comment.closing_reason }}
                            </span>
                            <span v-else-if="comment.is_referral === 1" class="text-danger fontbold mr-1">
                              Referral Client Approval
                            </span>
                            <span v-else-if="comment.is_diamond_return === 1" class="text-danger fontbold mr-1">
                              Diamond Return Client Approval
                            </span>
                            <span v-else-if="comment.is_closed === 1" class="text-danger fontbold mr-1">
                              {{ comment.closing_reason }}
                            </span>
                            <span v-else-if="comment.is_feedback === 1" class="text-info fontbold mr-1">
                              Feedback
                            </span>
                            <span v-else class="text-primary fontbold mr-1">
                              Comment
                            </span>
                            <span class="text-black-50 mr-2">{{ comment.user_name }}</span>
                            <span class="small">{{ formatDateTime(comment.created_at) }}</span>
                            <span v-if="comment.is_transferred === 1 && !comment.reply">
                              <button class="btn-success ml-1 border border-none" style="border-radius: 5px;"
                                :disabled="!isApproveAndRejectPermission"
                                @click="feedbackreplay(comment.id, index, 'transfer_approve')">
                                Approve
                              </button>
                            </span>
                            <span v-else>
                              <button v-if="comment.is_referral === 1 && !comment.reply"
                                class="btn-success ml-1 border border-none" style="border-radius: 5px;"
                                :disabled="!isApproveAndRejectPermission"
                                @click="feedbackreplay(comment.id, index, 'referral_approve')">Approve</button>
                              <button v-if="comment.is_diamond_return === 1 && !comment.reply"
                                class="btn-success ml-1 border border-none" style="border-radius: 5px;"
                                :disabled="!isApproveAndRejectPermission"
                                @click="feedbackreplay(comment.id, index, 'diamond_return_approve')">Approve</button>
                              <button v-if="comment.is_closed === 1 && !comment.reply"
                                class="btn-success ml-1 border border-none" style="border-radius: 5px;"
                                :disabled="!isApproveAndRejectPermission"
                                @click="feedbackreplay(comment.id, index, 'approve')">Approve</button>
                            </span>
                            <br />
                            <span class="small">- {{ comment.comment }} </span>
                            <div v-if="comment.is_diamond_return === 1 || comment.is_referral === 1 || comment.is_feedback === 1 || comment.is_closed === 1
                              || comment.is_transferred === 1" class="d-flex">
                              <div style="width: 20px;margin-left: 20px;">
                                <i class=" fa-solid fa-turn-up fa-rotate-90 " style="color: #d0d1d2;"></i>
                              </div>
                              <div style="width: calc( 100% - 20px)">
                                <ValidationObserver :ref="'approvedreject-' + index" v-slot="{ handleSubmit }">
                                  <form novalidate @submit.prevent="handleSubmit(feedbackreplay)">
                                    <span v-if="comment.reply">
                                      {{ comment.reply }}
                                    </span>
                                    <span class="comment-replay" v-else style="display: block !important;">
                                      <ValidationProvider vid="Feedback" name="Reply" rules="required"
                                        v-slot="{ errors }">
                                        <input type="text" :id="'Feedback' + index" name="Reply"
                                          class=" border border-none" v-model="reply[index]"
                                          :disabled="!isApproveAndRejectPermission && !selectedLeadSelfLeadAttribute"
                                          :class="errors.length > 0 ? ' is-invalid' : ''">
                                        <div class="invalid-feedback">
                                          <span>{{ errors[0] }}</span>
                                        </div>
                                      </ValidationProvider>
                                      <div>
                                        <button v-if="comment.is_feedback === 1" type="button"
                                          class="btn-success border border-success" style="color: #fff;"
                                          @click="handleSubmit(() => feedbackreplay(comment.id, index, 'reply'))">Replay</button>
                                        <button v-if="comment.is_referral === 1"
                                          :disabled="!isApproveAndRejectPermission"
                                          @click="handleSubmit(() => feedbackreplay(comment.id, index, 'referral_reject'))"
                                          type="button" class="btn-danger border border-danger">Reject</button>
                                        <button v-if="comment.is_diamond_return === 1"
                                          :disabled="!isApproveAndRejectPermission"
                                          @click="handleSubmit(() => feedbackreplay(comment.id, index, 'diamond_return_reject'))"
                                          type="button" class="btn-danger border border-danger">Reject</button>
                                        <button v-if="comment.is_transferred === 1"
                                          :disabled="!isApproveAndRejectPermission"
                                          @click="handleSubmit(() => feedbackreplay(comment.id, index, 'transfer_reject'))"
                                          type="button" class="btn-danger border border-danger">Reject</button>
                                        <button v-if="comment.is_closed === 1" :disabled="!isApproveAndRejectPermission"
                                          @click="handleSubmit(() => feedbackreplay(comment.id, index, 'reject'))"
                                          type="button" class="btn-danger border border-danger">Reject</button>
                                      </div>
                                    </span>
                                  </form>
                                </ValidationObserver>
                              </div>
                            </div>
                          </div>

                        </li>
                      </ul>
                      <!-- </span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import store from "@/store/index.js";
export default {
  name: 'leadFeedbackComments-modal',
  data: () => ({
    newComment: '',
    selectedLeadRating: '',
    selectedLeadSelfLeadAttribute: false,
    lead_feedback_comment: [],
    selectedReason: {
      queue: "",
      reason: "",
      company: ""
    },
    reply: [],
    isClosing: false,
    replyValidations: [],
    applyValidation: true,
    companies: [],
    userId: JSON.parse(atob(localStorage.getItem('user'))).id
  }),
  props: {
    selectedLead: Object,
    // selectedClient: Object,
    getAll: {
      type: Function,
      required: true,
    },
  },
  methods: {
    hasCloseOtherLeadPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-close-other";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasApproveAndRejectPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-close-approve-reject";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasDiamondReturnApproveAndRejectPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-diamond-return-approve-reject";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasleadsFeedBackPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-feedback";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },

    setSelectedLeadRating(rate_request) {
      this.selectedLeadRating = rate_request;
      this.ratingsUpdate();
    },

    ratingsUpdate() {
      const _vm = this;
      this.axios
        .post("/lead-ratings", {
          lead_id: _vm.selectedLead.id,
          rate_request: _vm.selectedLeadRating,
        })
        .then(function (response) {
          _vm.selectedLead.rate_request = _vm.selectedLeadRating;
          _vm.getAll(false);
        })
        .catch(function (error) { });
    },
    getSelectedLeadFeedBack() {
      const _vm = this;
      store.state.isLoaderShow = true;
      _vm.axios
        .get("/get-lead-feedback/" + _vm.selectedLead.id)
        .then(function (response) {
          _vm.lead_feedback_comment = response.data.data;
          store.state.isLoaderShow = false;
        })
        .catch(function (error) { });
    },
    handleSelection(event) {
      const selectedIndex = event.target.selectedIndex;
      const selectedOption = event.target.options[selectedIndex];
      const selectedOptionValue = selectedOption.value;
      let Queue = "";
      let Company = null;

      if (selectedOptionValue.startsWith("Transferred to")) {
        Queue = "Transferred";
        Company = selectedOptionValue.replace("Transferred to ", "");
        let c = this.companies.find(comp => comp.name === Company)
        Company = c.slug;
      } else {
        switch (selectedOptionValue) {
          case "Move to bonus queue":
            Queue = "Bonus";
            break;
          case "Duplicate lead":
            Queue = "Duplicate";
            break;
          case "Contact info is not correct":
            Queue = "Wrong_Contact_Info";
            break;
          case "Not reachable -Followup done":
            Queue = "Follow_Up_Done";
            break;
          case "Ask for Referral Client Approval":
            Queue = "ReferralApprovalPending";
            break;
          case "Ask for Diamond Return Client Approval":
            Queue = "DiamondReturnPending";
            break;
          case "Client is not ready yet":
            Queue = "Client_Not_Ready";
            break;
          case "Travel date past":
            Queue = "Expired";
            break;
          case "Trip cancelled":
          case "Booked Somewhere else":
          case "Another agent working with client":
          case "Other specific reason":
            Queue = "Closed";
            break;
        }
      }

      this.selectedReason.queue = Queue;
      this.selectedReason.reason = selectedOptionValue;
      this.selectedReason.company = Company;
    },


    addComment(isFeedback) {
      let _vm = this;
      _vm.applyValidation = true;
      _vm.$refs.replyfeedback.validate().then(success => {
        if (success || !_vm.applyValidation) {
          if (_vm.newComment != "") {
            let postData = {
              lead_id: [_vm.selectedLead.id],
              comment: _vm.newComment,
              is_feedback: isFeedback,
            };
            if (_vm.selectedReason && _vm.selectedReason.reason !== "") {
              postData.closing_reason = _vm.selectedReason;
            }

            if (isFeedback === 1) {
              postData.feedback = _vm.feedback;
            }
            _vm.axios
              .post("/lead-feedback", postData)
              .then(function (response) {
                _vm.lead_feedback_comment = response.data.data;
                _vm.selectedLead.lead_feedback_comment = _vm.lead_feedback_comment;
                _vm.newComment = "";
                _vm.selectedReason.reason = "";
                _vm.getAll(false);
                _vm.applyValidation = false;
              })
              .catch(function (error) { });
          }
        }
      });
    },
    enableValidation() {
      this.applyValidation = true;
    },

    feedbackreplay(id, index, type) {
      let _vm = this;

      if (type === 'approve' || type === 'referral_approve' || type === 'diamond_return_approve' || type === 'transfer_approve') {
        const reply = (type === 'approve' || type === 'referral_approve' || type === 'diamond_return_approve' || type === 'transfer_approve') ? 'Approved' : _vm.reply[index];
        _vm.axios
          .post("lead-feedback-reply", { id: id, type: type, reply: reply })
          .then(function (response) {
            _vm.$set(_vm.lead_feedback_comment[index], 'reply', 'Approved');
            if (type === 'approve' || type === 'referral_approve' || type === 'diamond_return_approve') {
              _vm.$set(_vm.reply, index, '');
            } else {
              _vm.$set(_vm.reply, index, '');
            }
            _vm.getAll(false);
          })
          .catch(function (error) { });
      } else {
        if (type === 'reply' && !_vm.reply[index]) {
          return;
        }

        if (_vm.$refs[`approvedreject-${index}`] && _vm.$refs[`approvedreject-${index}`][0]) {
          _vm.$refs[`approvedreject-${index}`][0].validate().then(success => {
            if (success) {
              const replyfeedback = {
                id: id,
                reply: type === 'approve' ? 'Approved' : _vm.reply[index],
                type: type
              }
              _vm.axios
                .post("lead-feedback-reply", replyfeedback)
                .then(function (response) {
                  _vm.$set(_vm.lead_feedback_comment[index], 'reply', replyfeedback.reply);
                  if (type === 'approve' || type === 'referral_approve' || type === 'diamond_return_approve') {
                    _vm.$set(_vm.reply, index, '');
                  } else {
                    _vm.$set(_vm.reply, index, '');
                  }
                  _vm.getAll(false);
                })
            }
          });
        }
      }
    },

    formatDateTime(timestamp) {
      const date = new Date(timestamp);
      const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

      const month = months[date.getMonth()];
      const day = date.getDate().toString().padStart(2, '0');
      const year = date.getFullYear().toString().slice(2);
      const time = timestamp.slice(11, 16);

      return `${month}/${day}/${year} ${time}`;
    },
    closeModal() {
      this.isClosing = true;
      setTimeout(() => {
        this.$emit('close');
      }, 400);
    },
    initializeCompanies() {
      const companiesString = process.env.VUE_APP_TRANSFERS_TO_COMPANIES;
      if (companiesString) {
        this.companies = companiesString.split(',').map(slug => {
          const name = slug.replace(/_/g, ' ').split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          return {
            name: name,
            slug: slug
          };
        });
      }
    },
  },
  computed: {
    isSelectDisabled() {
      return this.lead_feedback_comment.some(
        (comment) => comment.is_feedback === 1 && comment.reply === null
      );
    },

    isCloseOtherLeadPermission() {
      return this.hasCloseOtherLeadPermission();
    },
    isApproveAndRejectPermission() {
      const permission = this.hasApproveAndRejectPermission();
      return permission;
    },
    isDiamondReturnApproveAndRejectPermission() {
      const permission = this.hasDiamondReturnApproveAndRejectPermission();
      return permission;
    },
    isleadsFeedBackPermission() {
      const permission = this.hasleadsFeedBackPermission();
      return permission;
    },
  },
  mounted() {

    let _vm = this;

    _vm.getSelectedLeadFeedBack();
    if (_vm.selectedLead && _vm.selectedLead.rate_request !== undefined) {
      _vm.selectedLeadRating = _vm.selectedLead.rate_request;
    }
    if (_vm.selectedLead && _vm.selectedLead.lead_feedback_comment !== undefined) {
      _vm.lead_feedback_comment = _vm.selectedLead.lead_feedback_comment;
    }
    _vm.selectedLeadSelfLeadAttribute = _vm.selectedLead.assign_to == _vm.userId;

    _vm.initializeCompanies();
  }
}
</script>

<style scoped>
.modal {
  --bs-modal-margin: 0rem;
  --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.modal-mask {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-dialog {
  top: 0;
  right: 0;
  height: 100vh;
  position: absolute;
  width: 35%;
  border-radius: 0;
  animation: slideInFromLeft 0.5s ease forwards;
}

.modal-content {
  border-radius: 0px;
}

.modal-body {
  min-height: 100vh;
  max-height: 100vh;
}

.commentDivHeight {
  min-height: calc(100vh - 365px);
  max-height: calc(100vh - 365px);
  overflow-x: hidden;
}

.form-dropdown {
  display: block;
  border-radius: 8px;
  height: 2em;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: visible;
}

.pointer {
  cursor: pointer;
}

.xyz {
  background-color: blue;
}

.btnwidhei {
  width: 25px;
  height: 25px;
}

.dropdown-scroll {
  position: relative;
  z-index: 5000;
}

.closeDiv {
  height: 20px;
}

.modal-dialog1 {
  width: 35%;
  border-radius: 0;
}

.modal-dialog1 .modal-body {
  min-height: auto;
  max-height: auto;
}

.modal-dialog1 .modal-content {
  border-radius: 0px;
  display: block;
  top: 100px;
  left: 80%;
  width: 135%;
}

.mx-datepicker-popup {
  z-index: 9999 !important;
}

.modal-closing {
  animation: closeInFromLeft 0.5s ease forwards;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes closeInFromLeft {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

.comment-replay {
  display: block !important;
  position: relative;
  padding-bottom: 20px;
}

.comment-replay span {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 55px);
}

.comment-replay span input[type='text'] {
  width: 100%;
}

.comment-replay>div {
  display: inline-block;
  vertical-align: middle;
  width: 55px;
}

.comment-replay span>div {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
