<template>
  <div id="quotation-mail">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal modal-wrapper">
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content ">
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(validatePage)">
                  <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true" @click="$emit('close')">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body hei pt-0">
                    <div class="row">
                      <div class="col-md-12 ">
                        <div class="form-group d-flex">
                          <multiselect v-model="selectedEmail" :searchable="false" :hide-selected="true"
                            class="dropdoenselect mt-1 col-md-10" placeholder="Email" label="maskedValue"
                            track-by="value" :options="maskedEmailsList" :close-on-select="false" :multiple="true">
                          </multiselect>

                          <div
                            class="custom-control ml-2 mt-3 col-md-2 custom-checkbox custom-checkbox-color-check custom-control-inline float-right justify-content-end">
                            <input type="checkbox" class="custom-control-input bg-primary " id="customCheck-1"
                              v-model="isSelfChecked" />
                            <label class="custom-control-label mr-5" for="customCheck-1">To
                              Myself</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group d-flex">
                          <!-- <ValidationProvider vid="phone" name="phone" rules="required|phone" v-slot="{ errors }">
                                <multiselect v-model="selectedPhones" :searchable="false"
                                    :hide-selected="true" class="dropdoenselect mt-1 col-md-8"
                                    placeholder="To" label="maskedValue" track-by="value"
                                    :options="maskedPhonesList" :close-on-select="false"
                                    :multiple="true">
                                </multiselect>
                                <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                </div>
                            </ValidationProvider> -->
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group tempheight">
                          <div class="">
                            <p class="mb-2 subfont">Subject: {{ emailData.subject }}</p>
                          </div>
                          <div v-html="emailData.body"></div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="modal-footer d-flex justify-content-between col-md-12">
                    <div class="d-flex col-md-10">
                      <multiselect v-model="selectedPhones" :searchable="false" :hide-selected="true" disabled
                        class="dropdoenselect mt-1 " placeholder="Phone" label="maskedValue" track-by="value"
                        :options="maskedPhonesList" :close-on-select="false" :multiple="true">
                      </multiselect>
                      <!-- :disabled="!isSelfCheckedPhone" -->
                      <div
                        class="custom-control ml-2 mt-2 col-md-2 custom-checkbox custom-checkbox-color-check custom-control-inline float-right justify-content-end">
                        <input type="checkbox" class="custom-control-input bg-primary " id="customCheck-2" disabled />
                        <!--v-model="isSelfCheckedPhone" :disabled="phonesList.length == 0" -->
                        <label class="custom-control-label mr-5" for="customCheck-2">To
                          Phone</label>
                      </div>
                    </div>
                    <div>
                      <button type="submit" :disabled="selectedEmail.length == 0 && isSelfChecked == false || isLoading"
                        class="btn border-none btn-primary btn-sm">Send</button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<!-- <multiselect :searchable="false" disabled :hide-selected="true" class="dropdoenselect mt-1 "
                        placeholder="Phone" :options="maskedPhonesList" label="maskedValue" track-by="value"
                        :close-on-select="false" :multiple="true">
                      </multiselect>
                      <div
                        class="custom-control ml-2 mt-2 col-md-2 custom-checkbox custom-checkbox-color-check custom-control-inline float-right justify-content-end">
                        <input type="checkbox" class="custom-control-input bg-primary " id="customCheck-2" disabled />
                        <label class="custom-control-label mr-5" for="customCheck-2">To
                          Phone</label>
                      </div> -->

<script>
import Vue from "vue";
import Multiselect from 'vue-multiselect'
import CKEditor from "ckeditor4-vue";
Vue.use(CKEditor);
export default {
  data: () => ({
    error: "",
    selectedEmail: [],
    selectedPhones: [],
    userEmail: [],
    ischecked: true,
    isSelfChecked: false,
    isSelfCheckedPhone: false,
    isLoading: false,
    emailData: {
      emails: [],
      phones: [],
      subject: "",
      body: "",
      token: '',
    },
  }),
  components: {
    Multiselect
  },
  props: {
    leadId: {},
    quotationIds: {},
    selectedLead: Object,
    showMail: Boolean,
    title: String
  },

  computed: {
    emailsList() {
      return this.selectedLead.client.client_emails;
    },
    phonesList() {
      return this.selectedLead.client.client_phones;
    },
    user() {
      return this.$store.state.userDetail;
    },
    maskedEmailsList() {
      return this.emailsList.map(email => {
        const atIndex = email.value.indexOf('@');
        if (atIndex > 2) {
          const maskedValue = email.value.substring(0, 2) + '*'.repeat(atIndex - 2) + email.value.substring(atIndex - 1);
          return { ...email, maskedValue };
        } else {
          // Handle cases where the email is too short to mask properly
          return { ...email, maskedValue: email.value };
        }
      });
    },
    // maskedPhonesList() {
    //   return this.phonesList.map(phone => {
    //     const visibleChars = 2;
    //     const hiddenChars = phone.value.length - visibleChars - 3;
    //     const prefix = phone.prefix;
    //     const maskedValue = prefix + ' ' + phone.value.substring(0, visibleChars) + '*'.repeat(hiddenChars) + phone.value.substring(phone.value.length - 3);
    //     return { prefix: phone.prefix, value: phone.value, maskedValue: maskedValue };

    //   });
    // },

    maskedPhonesList() {
      return this.phonesList.map(phone => {
        const phoneLength = phone.value.length;
        const visibleChars = 2;
        const hiddenChars = phoneLength - visibleChars - 2; // Adjusted to show the last 2 characters

        let maskedValue;

        if (phoneLength > 4) {
          // Apply masking only if phone number has more than 4 characters
          maskedValue = phone.prefix + ' ' + phone.value.substring(0, visibleChars) + '*'.repeat(hiddenChars) + phone.value.substring(phoneLength - 2);
        } else {
          // No masking if phone number has 4 or fewer characters
          maskedValue = phone.prefix + ' ' + phone.value;
        }

        return { prefix: phone.prefix, value: phone.value, maskedValue: maskedValue };
      });
    },
  },

  watch: {
    isSelfChecked(newVal, oldVal) {
      if (newVal === true) {
        const atIndex = this.user.email.indexOf('@');
        const maskedValue = this.user.email.substring(0, 2) + '*'.repeat(atIndex - 2) + this.user.email.substring(atIndex - 1);
        this.selectedEmail.push({
          value: this.user.email,
          maskedValue: maskedValue
        });
      } else {
        const index = this.selectedEmail.findIndex(
          (email) => email.value === this.user.email
        );
        if (index !== -1) {
          this.selectedEmail.splice(index, 1);
        }
      }
    },
    selectedEmail(newVal, oldVal) {
      const userEmailIndex = newVal.findIndex(
        (email) => email.value === this.user.email
      );
      if (userEmailIndex === -1) {
        this.isSelfChecked = false;
      }
    },
    isSelfCheckedPhone(newVal) {
      if (!newVal) {
        this.selectedPhones = [];
        this.error = "";
      } else {
        if (this.maskedPhonesList.length > 0) {
          // this.selectedPhones = [this.maskedPhonesList[0]];
        }
        this.validateSelectedPhones();
      }
    },
    selectedPhones: {
      handler(newVal, oldVal) {
        if (newVal.length === 1) {
          this.isSelfCheckedPhone = true;
        }

        if (newVal.length === 0 && oldVal.length > 1) {
          return;
        }
        if (newVal.length === 0 && oldVal.length === 1) {
          this.isSelfCheckedPhone = false;
          return;
        }
        this.validateSelectedPhones();
      },
      deep: true
    }
  },
  methods: {
    validateSelectedPhones() {
      if (this.isSelfCheckedPhone && this.selectedPhones.length === 0) {
        this.error = "Please select a phone number.";
      } else {
        this.error = "";
      }
    },
    validatePage() {
      let _vm = this;
      this.isLoading = true;
      let ids = [];
      ids = _vm.quotationIds
      _vm.emailData.emails = []
      _vm.emailData.phones = []
      this.selectedEmail.forEach(element => {
        _vm.emailData.emails.push(element.value)
      });
      this.selectedPhones.forEach(element => {
        const phoneWithPrefix = element.prefix + element.value;
        _vm.emailData.phones.push(phoneWithPrefix);
      });
      this.axios
        .post(`/send-quotation-email`, {
          quotation_ids: ids,
          config: _vm.emailData,
        })
        .then(function () {
          _vm.$emit('send-email');
        })
        .catch(function (error) {
        })
        .finally(function () {
          _vm.isLoading = false;
          _vm.$emit('close');
        });

    },
    getPreviewEmail() {
      let _vm = this;
      _vm.showLoader = true;
      const params = {
        leadId: _vm.leadId,
        quotationIds: _vm.quotationIds
      };

      this.axios
        .get('quotation-email-preview', { params })
        .then(function (response) {
          _vm.showLoader = false;
          _vm.emailData.subject = response.data.data.subject;
          _vm.emailData.body = response.data.data.body;
          _vm.emailData.token = response.data.data.token;
          _vm.isSelfCheckedPhone = _vm.phonesList !== null && _vm.phonesList.length > 0;
        })
        .catch(function (error) {
          _vm.isSelfCheckedPhone = false;
        });
    }
  },
  mounted() {
    this.getPreviewEmail();
  }
}
</script>

<style scoped>
.custom-switch-label::before {
  left: 25px;
}

.custom-switch-label {
  background-color: #535854 !important;
}

.custom-switch-label::after {
  padding-left: 8px;
  padding-left: 8px;
}

.custom-switch-text {
  width: 80px !important;
}

.posdiv {
  position: absolute;
  width: 100%;
}

.toFixed {
  position: fixed;
  width: 82%;
  z-index: 1;
  background-color: white;
  margin-top: 0px;
}

.custom-switch-text .switch-input:checked~.switch-handle {
  left: 55px !important;
}

.iti {
  width: 100%;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
}

multiselect {
  position: relative;
}

.tempheight {
  height: calc(100vh - 300px);
  overflow-y: scroll;
  margin-bottom: 0;
}

.modal-body.hei {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
}

.subfont {
  font-size: medium;
  font-weight: 700;
}

.ck-editor__editable {
  height: auto;
  min-height: 350px;
}
</style>
